import { gql } from "@apollo/client"

export const CURRENT_USER = gql`
  query currentUser {
    currentUser {
      _id
      userData {
        _id
        fname
        surname
        email
        img
        company
      }
    }
  }
`
