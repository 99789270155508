import React from "react"
import { Helmet } from "react-helmet"
import Wizard from "../components/wizard/Wizard"
import { Router } from "@reach/router"

const KosikPage = ({ location }) => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Nákupní košík</title>
    </Helmet>
    <div className="my-10 mb-32">
      <Router>
        <Wizard path="/kosik/:step" />
        <Wizard path="/kosik" />
      </Router>
    </div>
  </>
)

export default KosikPage
