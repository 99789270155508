import React, { useState, useEffect } from "react"
import { Error } from "../form/Error"
import { AddressSelect } from "./AddressSelect"
import { XIcon } from "@heroicons/react/24/solid"

export const Address = ({
  register,
  unregister,
  errors,
  setValue,
  addressData,
  sourceData,
  updateAction,
  prefix,
}) => {
  const [disabled, setDisabled] = useState()
  const [degree, setDegree] = useState()
  const [selectedAddress, setAddress] = useState()
  const [selectedType, setType] = useState("business")

  useEffect(
    () => {
      if (prefix === "transport") {
        if (sourceData?.email) {
          setValue("email", sourceData.email)
          setDisabled(true)
        } else {
          setDisabled(false)
        }
      }
    },
    // eslint-disable-next-line
    []
  )

  useEffect(
    () => {
      if (addressData && addressData[selectedAddress]) {
        Object.keys(addressData[selectedAddress]).forEach(key => {
          if (key === "tel") {
            setValue(key, addressData[selectedAddress][key])
          } else {
            setValue(prefix + "_" + key, addressData[selectedAddress][key])
          }
        })
        setValue(prefix + "_address_id", addressData[selectedAddress].id)
      } else {
        if (
          sourceData?.data &&
          sourceData?.data.hasOwnProperty(prefix) &&
          sourceData?.data[prefix]
        ) {
          Object.keys(sourceData?.data[prefix]).forEach(key => {
            if (key === "company" && sourceData?.data[prefix][key]) {
              setType("business")
            }
            if (key === "tel") {
              setValue(key, sourceData?.data[prefix][key])
            } else {
              setValue(prefix + "_" + key, sourceData?.data[prefix][key])
            }
          })
        } else {
          setValue(prefix + "_street")
          setValue(prefix + "_city")
          setValue(prefix + "_zip")
          setValue(prefix + "_address_id")
        }
      }
    },
    // eslint-disable-next-line
    [selectedAddress, sourceData]
  )

  useEffect(
    () => {
      if (!selectedAddress && addressData) {
        setAddress(0)
      }
    },
    // eslint-disable-next-line
    [addressData]
  )

  const TypeButton = ({ text, type, ...props }) => {
    return (
      <>
        <button
          type="button"
          onClick={() => setType(type)}
          className={
            "focus:outline-none border-2 border-gray-200 px-5 py-1 w-36 rounded-md  font-medium flex  items-center   " +
            (selectedType === type
              ? "border-sky-200 text-white bg-sky-600 hover:bg-sky-700 "
              : "bg-gray-100 text-gray-400 hover:bg-gray-200 hover:text-gray-600")
          }
        >
          {props.children}
        </button>
      </>
    )
  }

  const CompanyData = () => {
    return (
      <>
        <div className="sm:col-span-4">
          <label
            htmlFor="company"
            className="block text-sm font-medium text-gray-700"
          >
            Název firmy
          </label>
          <div className="mt-1">
            <input
              type="text"
              id="company"
              {...register(prefix + "_company", { required: true })}
              className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
            {errors[prefix + "_company"] && (
              <Error text="Vyplňte název firmy" />
            )}
          </div>
        </div>
        <div></div>

        <div className="sm:col-span-2">
          <label
            htmlFor="inum"
            className="block text-sm font-medium text-gray-700"
          >
            IČ
          </label>
          <div className="mt-1">
            <input
              type="text"
              id="inum"
              {...register(prefix + "_inum", { required: true })}
              className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
            {errors[prefix + "_inum"] && <Error text="Vyplňte IČ" />}
          </div>
        </div>
        <div className="sm:col-span-2">
          <label
            htmlFor="inum"
            className="block text-sm font-medium text-gray-700"
          >
            DIČ
          </label>
          <div className="mt-1">
            <input
              type="text"
              id="vatnum"
              {...register(prefix + "_vatnum")}
              className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      {addressData && addressData.length > 0 && (
        <div className="relative   z-10">
          <AddressSelect
            addressData={addressData}
            selectedAddress={selectedAddress}
            setAddress={setAddress}
          />
        </div>
      )}

      <div className="space-x-2 flex ">
        <TypeButton type={"business"}>
          <svg
            className="w-5 mx-2"
            fill="currentColor"
            viewBox="0 -31 512 512"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m497.09375 60.003906c-.03125 0-.0625-.003906-.09375-.003906h-136v-15c0-24.8125-20.1875-45-45-45h-120c-24.8125 0-45 20.1875-45 45v15h-136c-8.351562 0-15 6.84375-15 15v330c0 24.8125 20.1875 45 
                45 45h422c24.8125 0 45-20.1875 45-45v-329.683594c0-.019531 0-.039062 0-.058594-.574219-9.851562-6.632812-15.199218-14.90625-15.253906zm-316.09375-15.003906c0-8.269531 6.730469-15 15-15h120c8.269531 
                0 15 6.730469 15 15v15h-150zm295.1875 45-46.582031 139.742188c-2.042969 6.136718-7.761719 10.257812-14.226563 10.257812h-84.378906v-15c0-8.285156-6.714844-15-15-15h-120c-8.285156 0-15 6.714844-15 
                15v15h-84.378906c-6.464844 0-12.183594-4.121094-14.226563-10.257812l-46.582031-139.742188zm-175.1875 150v30h-90v-30zm181 165c0 8.269531-6.730469 15-15 15h-422c-8.269531 0-15-6.730469-15-15v-237.566406l23.933594 
                71.796875c6.132812 18.40625 23.289062 30.769531 42.6875 30.769531h84.378906v15c0 8.285156 6.714844 15 15 15h120c8.285156 0 15-6.714844 15-15v-15h84.378906c19.398438 0 36.554688-12.363281 
                42.6875-30.769531l23.933594-71.796875zm0 0"
            />
          </svg>
          Firemní
        </TypeButton>
        <TypeButton type={"personal"}>
          <svg
            className="w-5 mx-2"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
            />
          </svg>
          Osobní
        </TypeButton>
      </div>

      <input type="hidden" {...register(prefix + "_address_id")} />

      <div className=" grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6 inset-0 relative ">
        {selectedType === "personal" ? (
          <>
            <div className="sm:col-span-3">
              <label
                htmlFor="first_name"
                className="block text-sm font-medium text-gray-700"
              >
                Jméno
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="first_name"
                  {...register(prefix + "_fname", { required: true })}
                  className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
                {errors?.fname && <Error text="Vyplňte jméno" />}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="last_name"
                className="block text-sm font-medium text-gray-700"
              >
                Příjmení
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="last_name"
                  {...register(prefix + "_surname", { required: true })}
                  className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
                {errors?.surname && <Error text="Vyplňte příjmení" />}
              </div>
            </div>

            {degree && (
              <>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="pre"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Titul před jménem
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="pre"
                      {...register(prefix + "_pre")}
                      className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="post"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Titul za jménem
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="post"
                      {...register(prefix + "_post")}
                      className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </>
            )}
            <div className="sm:col-span-6 flex justify-end text-sky-500 absolute right-0">
              {degree ? (
                <button
                  type="button"
                  onClick={() => setDegree(!degree)}
                  className="text-sm font-medium focus:outline-none flex items-center"
                >
                  <svg
                    className="inline h-5 mr-1"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>{" "}
                  Skrýt titul
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => setDegree(!degree)}
                  className="text-sm font-medium focus:outline-none flex items-center "
                >
                  <svg
                    className="inline h-5 mr-1"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>{" "}
                  Přidat titul
                </button>
              )}
            </div>
          </>
        ) : (
          <>
            <CompanyData />
          </>
        )}

        {prefix === "transport" && (
          <>
            <div className="sm:col-span-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                E-mail
              </label>
              <div className="mt-1 relative">
                <input
                  id="email"
                  type="email"
                  {...register("email", { required: true })}
                  disabled={disabled}
                  className={
                    "shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300 rounded-md " +
                    (disabled ? "bg-gray-200" : "")
                  }
                />
                {disabled && (
                  <>
                    <button
                      type="button"
                      aria-label="potvrdit"
                      onClick={() => updateAction(false)}
                      className="absolute right-2 top-1 text-gray-600 hover:text-gray-800 p-1"
                    >
                      <XIcon className="w-5 " />
                    </button>
                  </>
                )}
                {errors?.email && <Error text="Vyplňte e-mail" />}
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="tel"
                className="block text-sm font-medium text-gray-700"
              >
                Telefon
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="tel"
                  {...register("tel", { required: true })}
                  className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
                {errors?.tel && <Error text="Vyplňte telefon" />}
              </div>
            </div>
          </>
        )}
        <div className="sm:col-span-6">
          <label
            htmlFor="street"
            className="block text-sm font-medium text-gray-700"
          >
            Ulice, číslo domu
          </label>
          <div className="mt-1">
            <input
              type="text"
              id="street"
              {...register(prefix + "_street", { required: true })}
              className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
            {errors[prefix + "_street"] && <Error text="Vyplňte ulici" />}
          </div>
        </div>

        <div className="sm:col-span-4">
          <label
            htmlFor="city"
            className="block text-sm font-medium text-gray-700"
          >
            Město
          </label>
          <div className="mt-1">
            <input
              type="text"
              id="city"
              {...register(prefix + "_city", { required: true })}
              className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
            {errors[prefix + "_city"] && <Error text="Vyplňte město" />}
          </div>
        </div>

        <div className="sm:col-span-2">
          <label
            htmlFor="last_name"
            className="block text-sm font-medium text-gray-700"
          >
            PSČ
          </label>
          <div className="mt-1">
            <input
              type="text"
              id="zip"
              {...register(prefix + "_zip", { required: true })}
              className="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
            {errors[prefix + "_zip"] && <Error text="Vyplňte PSČ" />}
          </div>
        </div>
      </div>
    </>
  )
}
