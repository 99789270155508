import React, { useState, useEffect, useContext } from "react"
import { useForm } from "react-hook-form"
import { PageContext } from "../../context/context"
import {
  ChevronRightIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/solid"
import axiosInstance from "../../client/index.js"
import { Address } from "./Address.js"
import { Login } from "../layout/Login.js"
import Email from "./Email.js"
import { Loading } from "../layout/Loading.js"
import { navigate } from "@reach/router"

export default function Userdata(props) {
  const [state, dispatch] = useContext(PageContext)

  const [loading, setLoading] = useState(false)
  const [invoice, setInvoice] = useState(true)
  const [action, setAction] = useState(
    JSON.parse(localStorage.getItem("userDataStatus"))
  )
  const [addressData, setAddressData] = useState()
  const [createAccount, setCreateAccount] = useState(false)

  const [userData, setUserData] = useState()

  const isUser = state?.isLoggedIn
  const usersDataId = action?.data?.data?.usersDataId

  useEffect(
    () => {
      if (action?.data?.data?.invoice) {
        setInvoice(false)
      }
    },
    // eslint-disable-next-line
    []
  )

  function updateAction(data) {
    if (data) {
      localStorage.setItem("userDataStatus", JSON.stringify(data))
      setAction(data)
    } else {
      localStorage.removeItem("userDataStatus")
      setAction(false)
    }
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    unregister,
  } = useForm({
    mode: "onBlur",
  })

  useEffect(
    () => {
      if (invoice) {
        unregister("invoice_address_id")
        unregister("invoice_company")
        unregister("invoice_city")
        unregister("invoice_fname")
        unregister("invoice_surname")
        unregister("invoice_street")
        unregister("invoice_zip")
        unregister("invoice_inum")
        unregister("invoice_vatnum")
      }
    },
    // eslint-disable-next-line
    [invoice]
  )

  const onSubmit = async data => {
    setLoading(true)
    try {
      const res = await axiosInstance.post("users_data/signUp/", {
        data: data,
        email: action?.data?.email,
        usersDataId: action?.data?.usersDataId,
        cartId: state?.cart?.cart?.cart_id,
      })
      if (res.data) {
        updateAction({
          action: action?.action,
          data: {
            email: action?.data?.email,
            usersDataId: res.data.usersDataId,
            data: res.data,
          },
        })
        navigate("/kosik/3")
      }
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  async function getAddress() {
    if (!addressData) {
      try {
        const res = await axiosInstance.post("users_data/getAddress/", {
          usersDataId: state?.userData?._id,
        })
        if (res.data) {
          setAddressData(res.data)
        }
      } catch (err) {
        console.log(err)
      }
    }
  }

  function loginModal() {
    dispatch({
      type: "SET_MODAL",
      payload: {
        component: Login,
        params: {
          userData: userData,
          updateAction: updateAction,
        },
      },
    })
  }

  useEffect(
    () => {
      if (state?.isLoggedIn) {
        setUserData(JSON.parse(localStorage.getItem("userData")))
        getAddress()
        state?.userData &&
          updateAction({
            action: "user",
            data: {
              email: state?.userData?.email,
              usersDataId: state?.userData?._id,
            },
          })
      } else {
        setAddressData(null)
      }
    },
    // eslint-disable-next-line
    [state]
  )

  return (
    <>
      <div className={"" + (!action ? "" : "hidden")}>
        <Email data={action?.data} updateAction={updateAction} />
      </div>

      {action?.action === "login" && !isUser && (
        <button
          className="flex p-3 px-5 mx-auto items-center bg-gray-100 border border-gray-200 hover:bg-gray-200 rounded-md space-x-4 text-sm text-gray-700 justify-between"
          onClick={() => loginModal()}
        >
          <InformationCircleIcon className="w-5 h-5 text-gray-400" />
          <span>Přihlaste se ke svému účtu pro rychlé doplnění údajů</span>
          <ChevronRightIcon className="w-6 h-6 text-gray-400" />
        </button>
      )}

      {action?.action === "register" ||
      action?.action === "user" ||
      action?.action === "login" ? (
        <>
          <div className="text-lg">Dodací adresa</div>
          <form
            key={1}
            onSubmit={handleSubmit(onSubmit)}
            className={"space-y-8  py-2"}
          >
            <Address
              sourceData={action?.data}
              register={register}
              unregister={unregister}
              errors={errors}
              setValue={setValue}
              updateAction={updateAction}
              addressData={addressData}
              prefix={"transport"}
            />
            <div>
              {createAccount && (
                <input
                  id="create_account"
                  type="hidden"
                  {...register("create_account")}
                  defaultValue={1}
                  className="absolute"
                />
              )}
              <div
                role="button"
                tabIndex={0}
                className="inline-flex items-center sm:col-span-4 cursor-pointer w-auto "
                onClick={() => setInvoice(!invoice)}
                onKeyUp={() => setInvoice(!invoice)}
              >
                <button
                  type="button"
                  aria-pressed="false"
                  aria-labelledby="toggleLabel"
                  className={
                    (invoice ? "bg-sky-700" : "bg-gray-300") +
                    " relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none "
                  }
                >
                  <span className="sr-only">fakturační adresa je shodná</span>
                  <span
                    aria-hidden="true"
                    className={
                      (invoice ? "translate-x-5" : "translate-x-0") +
                      " pointer-events-none  inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                    }
                  ></span>
                </button>
                <span
                  className={
                    (invoice ? "text-gray-700 font-bold" : "text-gray-400") +
                    " text-sm flex space-x-2 ml-3"
                  }
                  id="toggleLabel"
                >
                  <span>fakturační adresa je shodná</span>
                </span>
              </div>

              {!invoice && (
                <div className="my-5 space-y-4">
                  <div className="text-lg ">Fakturační adresa</div>
                  <Address
                    sourceData={action?.data}
                    register={register}
                    errors={errors}
                    setValue={setValue}
                    updateAction={updateAction}
                    addressData={addressData}
                    prefix={"invoice"}
                  />
                </div>
              )}
              {!isUser && !usersDataId && (
                <div className="mt-4">
                  <div
                    role="button"
                    tabIndex={0}
                    className="inline-flex items-center sm:col-span-4 cursor-pointer w-auto "
                    onClick={() => setCreateAccount(!createAccount)}
                    onKeyUp={() => setCreateAccount(!createAccount)}
                  >
                    <button
                      type="button"
                      aria-pressed="false"
                      aria-labelledby="toggleLabel"
                      className={
                        (createAccount ? "bg-sky-700" : "bg-gray-300") +
                        " relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none "
                      }
                    >
                      <span className="sr-only">vytvořit uživatelský účet</span>
                      <span
                        aria-hidden="true"
                        className={
                          (createAccount ? "translate-x-5" : "translate-x-0") +
                          " pointer-events-none  inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                        }
                      ></span>
                    </button>
                    <span
                      className={
                        (createAccount
                          ? "text-gray-700 font-bold"
                          : "text-gray-400") + " text-sm flex space-x-2 ml-3"
                      }
                      id="toggleLabel"
                    >
                      <span>vytvořit uživatelský účet</span>
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="sm:col-span-2 mt-3 flex justify-end">
              <button className="flex items-center   text-sky-100 h-12  px-5 py-1 focus:outline-none  space-x-4 bg-sky-600 rounded-md border border-gray-200 hover:bg-sky-700">
                <span>Pokračovat</span>
                <ChevronRightIcon className="w-8 " />
              </button>
            </div>
          </form>
        </>
      ) : (
        ""
      )}
      <Loading loading={loading} />
    </>
  )
}
