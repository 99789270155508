import React from "react"
import { CheckIcon } from "@heroicons/react/24/solid"
import { Link } from "gatsby"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function Steps(props) {
  const steps = props.steps
  const currentActive = props.activeTab
  const tabs = props.tabs

  return (
    <div className="max-w-2xl mx-auto ">
      <nav aria-label="Progress">
        <ol className="flex items-center w-full justify-center">
          {steps &&
            steps.map((step, stepIdx) => (
              <li
                key={step.name}
                className={classNames(
                  stepIdx + 1 !== steps.length ? "pr-20 sm:pr-32" : "",
                  "relative"
                )}
              >
                {stepIdx + 1 < currentActive ? (
                  <>
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="h-0.5 w-full bg-blue-600" />
                    </div>
                    {tabs ? (
                      <>
                        <button
                          onClick={() => props.setActiveTab(stepIdx + 1)}
                          className="relative w-8 h-8 flex items-center justify-center bg-blue-600 rounded-full hover:bg-blue-900"
                        >
                          <CheckIcon
                            className="w-5 h-5 text-white"
                            aria-hidden="true"
                          />
                          <span className="sr-only">{step.name}</span>
                        </button>
                      </>
                    ) : (
                      <>
                        <Link
                          to={
                            stepIdx === 0
                              ? "/kosik"
                              : "/kosik/" + parseInt(stepIdx + 1)
                          }
                          onClick={() => props.setActiveTab(stepIdx + 1)}
                          className="relative w-8 h-8 flex items-center justify-center bg-blue-600 rounded-full hover:bg-blue-900"
                        >
                          <CheckIcon
                            className="w-5 h-5 text-white"
                            aria-hidden="true"
                          />
                          <span className="sr-only">{step.name}</span>
                        </Link>
                      </>
                    )}
                  </>
                ) : currentActive === stepIdx + 1 ? (
                  <>
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="h-0.5 w-full bg-gray-200" />
                    </div>
                    <span
                      className="relative w-8 h-8 flex items-center justify-center bg-white border-2 border-blue-600 rounded-full"
                      aria-current="step"
                    >
                      <span className="font-bold text-blue-600">
                        {stepIdx + 1}
                      </span>
                    </span>
                  </>
                ) : (
                  <>
                    <div
                      className="absolute inset-0 flex items-center"
                      aria-hidden="true"
                    >
                      <div className="h-0.5 w-full bg-gray-200" />
                    </div>
                    <span className="group relative w-8 h-8 flex items-center justify-center bg-white border-2 border-gray-300 rounded-full  ">
                      <span className="  text-gray-400">{stepIdx + 1}</span>
                    </span>
                  </>
                )}
              </li>
            ))}
        </ol>
      </nav>
    </div>
  )
}
