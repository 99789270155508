import React, { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { XIcon, MinusIcon, PlusIcon } from "@heroicons/react/24/outline"
import { BsPercent } from "react-icons/bs"
import { Link } from "gatsby"

export const CartItem = ({ item, deleteItem, updateItem, summary }) => {
  const { register, handleSubmit, getValues } = useForm({
    mode: "onBlur",
  })

  const [count, setCount] = useState(parseInt(item.count))
  const [note, setNote] = useState(item.options ? item.options : "")

  const onSubmit = async data => {
    setCount(data.count)
  }
  let potisk
  function updateNote() {
    note ? (potisk = "firemní potisk") : (potisk = "")
    updateItem(item.id, count, potisk)
  }

  useEffect(() => {
    updateNote()
    // eslint-disable-next-line
  }, [note])

  const debounce = (func, debounceTimeout) => {
    let timeout
    return function executedFunction(...args) {
      const delayedFunction = () => {
        clearTimeout(timeout)
        func(...args)
      }
      clearTimeout(timeout)
      timeout = setTimeout(delayedFunction, debounceTimeout)
    }
  }

  // eslint-disable-next-line
  const debouncedSubmit = useCallback(
    debounce(name => {
      setCount(getValues("count"))
    }, 700),
    []
  )

  function change() {
    debouncedSubmit()
  }

  return (
    <>
      <div
        key={item.id}
        className={
          "grid grid-cols-4 lg:grid-cols-7 w-full text-gray-800 items-center border-t  pin-r pin-y  pt-3 px-3 pb-4 "
        }
        cellSpacing="0"
      >
        <Link
          className="  w-16 h-16 flex items-center justify-center"
          to={"/produkty/detail/" + item.alias + "/"}
        >
          {item.type !== "cart_promo" && item?.img ? (
            <img
              src={item?.img}
              alt={item?.name}
              className="w-full h-full object-center object-cover sm:rounded-lg"
            />
          ) : (
            item.type === "cart_promo" && (
              <div className="bg-green-600 rounded-full mx-auto p-2">
                <BsPercent className="w-6 h-6 text-white" />
              </div>
            )
          )}
        </Link>

        <div
          className={
            "flex space-x-2  lg:space-x-0 justify-between " +
            (item.type !== "cart_promo" && item.type !== "eorder"
              ? summary
                ? "col-span-3 lg:col-span-4 "
                : "col-span-2 lg:col-span-3"
              : summary
              ? "col-span-2 lg:col-span-4 "
              : "col-span-2 lg:col-span-4")
          }
        >
          <div className="truncate grid items-center pr-5">
            {item.alias ? (
              <Link
                to={"/produkty/detail/" + item.alias + "/"}
                className="font-medium truncate"
              >
                {item.name}
              </Link>
            ) : (
              <>
                <span className="font-medium truncate">{item.name}</span>
              </>
            )}

            <div className="text-xs text-gray-500 grid sm:flex sm:space-x-6">
              {item.quantity > 0 ? (
                <span className=" text-green-600 font-bold">
                  {item.quantity === 1 && "Poslední kus"} skladem
                </span>
              ) : (
                item.note && (
                  <span className="text-gray-600">Dostupné {item.note}</span>
                )
              )}
              {item.type !== "cart_promo" && (
                <p>
                  {" "}
                  <span>
                    {parseFloat(item.price).toLocaleString("cs")} Kč
                  </span>{" "}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="  lg:col-span-1 flex flex-row items-center lg:justify-center text-gray-400  ">
          {!summary ? (
            <>
              <button
                type="button"
                onClick={() => setCount(parseInt(item.count) - 1)}
                className="bg-gray-200 text-gray-600  h-8 flex items-center rounded-l-md p-2 px-1 hover:bg-gray-700 hover:text-white"
              >
                <MinusIcon className="w-4 h-4 cursor-pointer" />
              </button>
              <form onSubmit={handleSubmit(onSubmit)}>
                <input
                  type="number"
                  {...register("count", { required: true })}
                  defaultValue={count}
                  onInput={() => change()}
                  min="1"
                  className="shadow-sm focus:ring-sky-500 focus:border-sky-500 text-gray-700 block text-lg w-16 h-8 pr-0 text-center   border-gray-300 "
                />
              </form>
              <button
                type="button"
                onClick={() => setCount(parseInt(item.count) + 1)}
                className="bg-gray-200 text-gray-600  h-8 flex items-center rounded-r-md p-2 px-1 hover:bg-gray-700 hover:text-white"
              >
                <PlusIcon className="w-4 h-4  " />
              </button>
            </>
          ) : (
            <span className="text-black">{item.count} ks</span>
          )}
        </div>
        <div className="col-span-4 lg:col-span-2 flex justify-between items-center space-x-5 ">
          <div className="w-1/2 text-right">
            {parseFloat(item.price * item.count).toLocaleString("cs")} Kč
          </div>
          {!summary && (
            <div className=" justify-end items-center lg:flex">
              <button
                type="button"
                onClick={() => deleteItem(item.id)}
                className="text-gray-400 hover:text-gray-900 focus:outline-none py-3"
              >
                <XIcon className="w-6 text-gray-500" />
              </button>
            </div>
          )}
        </div>
        <div className=" col-span-5">
          <div
            role="button"
            tabIndex={0}
            className="inline-flex items-center sm:col-span-4 cursor-pointer w-auto ml-2 lg:mt-4 mr-2"
            onClick={() => setNote(!note)}
            onKeyDown={() => setNote(!note)}
          >
            <button
              type="button"
              aria-pressed="false"
              aria-labelledby="toggleLabel"
              className={
                (note ? "bg-amber-500" : "bg-gray-300") +
                " relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none "
              }
            >
              <span className="sr-only">firemní potisk této položky</span>
              <span
                aria-hidden="true"
                className={
                  (note ? "translate-x-5" : "translate-x-0") +
                  " pointer-events-none  inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                }
              ></span>
            </button>
            <span
              className={
                (note ? "text-gray-700 font-bold" : "text-gray-400") +
                " text-sm flex space-x-2 ml-3"
              }
              id="toggleLabel"
            >
              <span>firemní potisk této položky</span>
            </span>
          </div>
        </div>
      </div>
    </>
  )
}
