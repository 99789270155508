import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { Error } from "../form/Error"
import { ChevronRightIcon } from "@heroicons/react/24/solid"
import { Loading } from "../layout/Loading"
//import { AuthContext } from "../../context/context";
import axiosInstance from "../../client"
import { XIcon } from "@heroicons/react/24/solid"

export function Logout({ setUser }) {
  setUser(false)
  localStorage.removeItem("user")
}

export default function Email(props) {
  const data = props.data
  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()
  const [done, setDone] = useState()

  const onSubmit = async data => {
    setLoading(true)
    try {
      const res = await axiosInstance.post("users_data/checkEmail/", {
        email: data.email,
      })
      if (res.data) {
        props.updateAction({
          action: res.data.message,
          data: {
            email: data.email,
          },
        })
      }
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  // const authContext = useContext(AuthContext);
  // const loginHandler = () => {
  //     //API call to server...
  //     //response from server
  //     const userResponse = {
  //         token: "abjd2323jb443jbbb"
  //     };
  //     authContext.login(userResponse.token);//setAuthContext
  // };
  // const logoutHandler = () => {
  //     authContext.logout();
  // };

  useEffect(
    () => {
      if (data?.email) {
        setValue("email", data.email)
        setDone(true)
      } else {
        setDone(false)
      }
    },
    // eslint-disable-next-line
    [data]
  )

  return (
    <>
      <div className="flex bg-white rounded-lg overflow-hidden mx-auto max-w-sm lg:max-w-1xl">
        <div className="w-full p-8 pb-0  ">
          <form onSubmit={handleSubmit(onSubmit)}>
            <>
              <div className="mt-4 relative">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  {done ? "Váš e-mail" : "Zadejte svůj e-mail"}
                </label>
                <input
                  type="text"
                  {...register("email", { required: true })}
                  disabled={done}
                  className={
                    " text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none " +
                    (done ? "bg-gray-200" : "")
                  }
                />
                {done && (
                  <>
                    <button
                      type="button"
                      onClick={() => props.updateAction(false)}
                      className="absolute right-2 top-9 text-gray-600 hover:text-gray-800 p-1"
                    >
                      <XIcon className="w-5 " />
                    </button>
                  </>
                )}
                {errors?.login && <Error text={"Prosím vyplňte e-mail"} />}
              </div>
            </>
            {!done && (
              <div className="mt-3 flex justify-end">
                <button className="flex items-center   text-blue-100 h-12  px-5 py-1 focus:outline-none  space-x-4 bg-blue-600 rounded-md border border-gray-200 hover:bg-blue-700">
                  <span>Pokračovat</span>
                  <ChevronRightIcon className="w-8 " />
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
      <Loading loading={loading} />
    </>
  )
}
