import React from "react"
import { CartItem } from "./CartItem"

function CartItemslist({
  cartItems,
  deleteItem,
  updateItem,
  lastCartItem,
  summary,
}) {
  return (
    <div className="divide-y ">
      {cartItems &&
        cartItems.length > 0 &&
        cartItems.map(item => (
          <>
            <CartItem
              item={item}
              updateItem={updateItem}
              deleteItem={deleteItem}
              summary={summary}
            />
          </>
        ))}
      <div />
    </div>
  )
}

export default CartItemslist
